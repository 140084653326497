import React from 'react';
// import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="fixed top-0 left-0 w-full z-20 bg-transparent text-white p-6">
      <div className="container mx-auto flex justify-end items-center space-x-6">
        <a
          href="#hero"
          className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition duration-300"
        >
          Home
        </a>
        <a
          href="#about"
          className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition duration-300"
        >
          About Us
        </a>
        <a
          href="#services"
          className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition duration-300"
        >
          Services
        </a>
        {/* <a
          href="#contact"
          className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition duration-300"
        >
          Contact
        </a> */}
      </div>
    </header>
  );
};

export default Header;
